<template>
  <div class="relative flex items-center flex-nowrap">
    <!-- Image -->
    <div class="flex-none h-auto w-[60px] mr-2">
      <img
        class="w-full h-full rounded-lg shadow-md"
        :alt="audioPlayerNewStore.currentAudio.title"
        :src="audioPlayerNewStore.currentAudio.logoUrlOverview"
      >
    </div>

    <!-- Title -->
    <div class="flex-grow">
      <p class="w-full text-sm font-medium text-white line-clamp-1">
        {{ audioPlayerNewStore.currentAudio.title }}
      </p>
      <p
        v-if="audioPlayerNewStore.currentAudio.type !== 'intro'"
        class="w-full line-clamp-1 text-[10px] text-dark-100"
      >
        {{ audioPlayerNewStore.currentAudio.type }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAudioPlayerNewStore } from '@/stores/audioPlayerNew'

const audioPlayerNewStore = useAudioPlayerNewStore()
</script>
